import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BASE_URL } from '@core/lib/api.factory';
import { GqlSearchQuery, SearchModel, SearchQuery } from '@core/models/product/search.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SLUGS_TO_EXCLUDE } from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(
    private http: HttpClient,
    @Inject(BASE_URL) private baseUrl: string,
  ) {
  }

  search(query: SearchQuery): Observable<SearchModel[]> {
    const gqlQuery = new GqlSearchQuery(query);
    return this.http.get<{data: SearchModel[]}>(`${this.baseUrl}/store/product/search?${gqlQuery.toRest()}`).pipe(
      map( res => this.filterProductsBySlugs(res.data))
    );
  }

  // https://control.wr.market/issues/35331
  private filterProductsBySlugs(products: SearchModel[]) {
    return products.filter((product: SearchModel) => !SLUGS_TO_EXCLUDE.includes(product.slug));
  }
}
