export const environment = {
  appName: 'vertera_dev',
  production: false,
  multilangServer: false,
  baseUri: 'https://api.new.vertera.org/ru/v1',
  host: 'https://dev.club.vertera.org/',
  staticUrl: 'https://static.vertera.org',
  angiolive: {
    token: '0a91d583-1ae7-495c-bfc3-e20fc055a20d',
    url: 'https://landings.dev.vertera.org/angiolive',
    paySuccessUrl: '/land/angiolive/payment?success=',
    yaMetrika: 55999003,
    tagIds: 48,
    b: {
      token: 'ec90ebd1-5b9c-4898-b865-5c9c8716e8cc',
    },
  },
  thalassolab: {
    token: '8b367642-fafd-42ca-8f3f-3ace1534baae',
    url: 'https://landings.dev.vertera.org/thalassolab',
    paySuccessUrl: '/land/thalassolab/payment?success=',
    mapsApiKey: 'AIzaSyC9ei_wsWjYkVpAMJkiuWq3ufsnkAhPvmg',
    yaMetrika: 56761948,
  },
  sensation: {
    token: 'e5c6bd03-d248-4193-8a4b-4a551fddf7dd',
    url: 'https://landings.dev.vertera.org/sensation',
    paySuccessUrl: '/land/sensation?success=',
  },
  miracle: {
    token: 'b967a72b-4e80-4818-930b-c54b2535dfe2',
    url: 'https://landings.dev.vertera.org/miracle',
    paySuccessUrl: '/land/miracle?success=',
  },
  verteraGel: {
    token: '95a6012c-3b55-4b50-b90b-bdf247fbbbb7',
    url: 'https://landings.dev.vertera.org/vertera-gel',
    yaMetrika: 56041366,
  },
  detoxJuice: {
    token: '314b15ef-ebb4-480f-a187-7ec005f0c5f7',
    url: 'https://landings.dev.vertera.org/detox-juice',
  },
  vertera: {
    yaMetrika: 61615645,
    token: '95939b4b-7e43-439e-b359-a6261ed26780',
    url: 'https://old.dev.vertera.org',
  },
  turkey: {
    url: 'https://dev.turkey.vertera.org',
  },
  azerbaijan: {
    url: 'https://dev.azer.vertera.org/az/',
  },
  eu: {
    url: 'https://dev.eu.vertera.org/',
  },
  authLink: {
    registration: 'https://dev.id.vertera.org/registration?service=vertera',
    login: 'https://dev.id.vertera.org/?service=vertera&across=1',
    idlogout:
      'https://dev.id.vertera.org/logout?return=https://dev.new.vertera.org/logout',
    lk: 'https://old.dev.vertera.org/user/cabinet',
  },
  refundLink: 'https://old.dev.vertera.org/en/retuns-refunds-policy',
  mapsApiKey: 'AIzaSyC9ei_wsWjYkVpAMJkiuWq3ufsnkAhPvmg',
};
