import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppModule } from './app.module';
import { AppStorage, CookieStorage } from '@core/storage';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';

// Language to port map for dev mode
export const languagePort = {
  4200: 'ru',
  4201: 'en',
  4202: 'es',
  4203: 'cs',
  4204: 'bg',
};

@NgModule({
  imports: [
    AppModule,
    BrowserAnimationsModule
  ],
  providers: [
    {
      provide: AppStorage,
      useClass: CookieStorage
    },
    {
      provide: REQUEST,
      useValue: {}
    },
    {
      provide: RESPONSE,
      useValue: {}
    }
  ],
  bootstrap: [AppComponent]
})
export class AppBrowserModule { }
