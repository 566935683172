import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GreenButtonComponent } from './green-button/green-button.component';
import { TransparentButtonComponent } from './transparent-button/transparent-button.component';
import { WhiteButtonComponent } from './white-button/white-button.component';
import { GreyButtonComponent } from './grey-button/grey-button.component';
import { IconModule } from '../icon/icon.module';
import { AddToCartComponent } from './add-to-cart/add-to-cart.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { VerteraCommonModule } from '@vertera-common';


@NgModule({
  imports: [
    CommonModule,
    IconModule,
    MatProgressSpinnerModule,
    VerteraCommonModule,
  ],
  declarations: [
    GreenButtonComponent,
    TransparentButtonComponent,
    WhiteButtonComponent,
    GreyButtonComponent,
    AddToCartComponent,
  ],
  exports: [
    GreenButtonComponent,
    TransparentButtonComponent,
    WhiteButtonComponent,
    GreyButtonComponent,
    AddToCartComponent,
  ],

})
export class ButtonsModule {
}
