import { Component, Input } from '@angular/core';
import { ButtonsBase } from '../buttonsBase';

@Component({
  selector: 'app-white-button',
  templateUrl: './white-button.component.html',
  styleUrls: ['./white-button.component.scss']
})
export class WhiteButtonComponent extends ButtonsBase {
  @Input() height: 'h40' | 'h44' | 'h48' | '';
  @Input() round: boolean;
  @Input() onlyborder: boolean;
  @Input() small: boolean;
  @Input() square: boolean;

  get _class(): string {
    const list = ['button', this.height];
    return list.join(' ');
  }

  constructor() {
    super();
  }

}
