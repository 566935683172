import { RedirectService } from '../redirectService';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { HttpParams, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RefService } from '../user/ref.service';
import { LanguageService } from '../language.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthTokenService } from './auth-token.service';
import { AuthSessionService } from './auth-session.service';
import { LANG_ID_MAPS } from '@core/lib/const/langs';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { CityService } from '../hdbk/city.service';

@Injectable()
export class AuthService extends RedirectService {
  private tokenKey = `${environment.appName}_token`;
  constructor(
    public router: Router,
    private cityService: CityService,
    @Inject(PLATFORM_ID) public platform: any,
    private route: ActivatedRoute,
    private refService: RefService,
    private languageService: LanguageService,
    private http: HttpClient,
    private authTokenService: AuthTokenService,
    private authSessionService: AuthSessionService,
    @Inject(RESPONSE) public response: any,
  ) {
    super(router, platform, response);
  }

  get loginLink(): string {
    return this.getAuthOrLoginLink(environment.authLink.login);
  }

  get logOutLink(): string {
    return environment.authLink.idlogout;
  }

  get regLink(): string {
    return this.getAuthOrLoginLink(environment.authLink.registration);
  }

  externalReg() {
    this.redirectToUrl(this.regLink, true);
  }

  externalLogin() {
    this.redirectToUrl(this.loginLink, true);
  }

  goToUserCabinet() {
    const lang = this.languageService.getLanguage();
    const lkLangLink = environment.authLink.lk.replace(/\.org\//, `.org/${ lang === 'ru' ?  '' : lang + '/' }`);
    this.redirectToUrl(lkLangLink, true);
  }

  logOut() {
    if (isPlatformBrowser(this.platform)) {
      this.redirectToUrl(this.logOutLink, true);
    }
  }

  getAuthOrLoginLink(link: string) {
    let redirect = isPlatformBrowser(this.platform) ? location.pathname.replace('external/authorization', '') : '/';
    if (/^(\/\w{2})\//.test(redirect)) {
      redirect = redirect.replace(/^\/\w{2}/, '');
    }
    const urlParams = new HttpParams({fromObject: this.route.snapshot.queryParams}).toString();
    const encodedRedirect = encodeURIComponent(`${redirect}?${urlParams}`);
    const encodedReturn = encodeURIComponent(`${environment.host}?redirect=${encodedRedirect}`);
    return `${link}${this.refService.getRefUrlToECA()}${this.getCountryQuery()}${this.getLanguageQuery()}&return=${encodedReturn}`;
  }

  signOs(session_key: string, redirect: string): Observable<any> {
    return this.http
      .post(`${environment.baseUri}/user/auth-os?field=token`, {
        session_key,
        token: this.authSessionService.getSessionHeader()
      })
      .pipe(
        map(data => {
          // tslint:disable-next-line: no-string-literal
          const dataObj = data['data'];
          if (dataObj && dataObj.token) {
            // DO: сделано на уровне server.ts, но на всякий оставлен тут.
            if (!isPlatformBrowser(this.platform)) {
              const monthMs = 2678400000; // 31 * 24 * 60 * 60 * 1000
              const expires = new Date(Date.now() + monthMs); // 1 month
              if (this.response) {
                this.response.cookie(this.tokenKey, dataObj.token, {
                  expires
                });
              } else {
                console.warn('this._response undefined');
              }
            } else {
              this.authTokenService.setAuthHeader(dataObj.token);
            }
            this.redirectToUrl(redirect);
          }
          return dataObj;
        })
      );
  }

  clearCookieAndExit(destination: string = '') {
    const url =
      destination === 'registration' ? environment.authLink.registration : '/';
    if (!isPlatformBrowser(this.platform)) {
      if (this.response) {
        this.response.cookie(this.tokenKey, '', { expires: new Date(0) });
      } else {
        console.warn('this._response undefined');
      }
      this.redirectToUrl(url);
    } else {
      this.authTokenService.clearAuthHeaderValue();
      setTimeout(() => {
        this.redirectToUrl(url, true);
      }, 300);
    }
  }

  private getLanguageQuery(): string {
    return `&language=${LANG_ID_MAPS[this.languageService.getLanguage()]}`;
  }

  private getCountryQuery(): string {
    return `&countryCode=${this.cityService.getCountryCode()}`;
  }

}
