import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageSwitcherComponent } from './language-switcher.component';
import { IconModule } from '../icon/icon.module';
import { RouterModule } from '@angular/router';
import { LanguageSwitcherBlackComponent } from './language-switcher-black/language-switcher-black.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { LanguageSwitcherSelectComponent } from './language-switcher-select/language-switcher-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [LanguageSwitcherComponent, LanguageSwitcherBlackComponent, LanguageSwitcherSelectComponent],
  imports: [
    CommonModule,
    IconModule,
    RouterModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    LanguageSwitcherComponent, LanguageSwitcherBlackComponent, LanguageSwitcherSelectComponent
  ]
})
export class LanguageSwitcherModule { }
