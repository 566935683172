import { NgSelectModule } from '@ng-select/ng-select';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { WrapperComponent } from './wrapper/wrapper.component';
import { GeoSelectComponent } from './header/components/geo-select/geo-select.component';
import {
  IconModule,
  LanguageSwitcherModule,
  ButtonsModule, CheckBoxModule,
} from '@ui';
import { VerteraCommonModule } from '@vertera-common';
import { BreadcrumbsComponent } from './header/components/breadcrumbs/breadcrumbs.component';
import { SearchFormComponent } from './header/components/search-form/search-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CategoryComponent } from './header/components/category/category.component';
import { StockPanelComponent } from './header/components/stock-panel/stock-panel.component';
import { ConfirmDialogComponent } from '@core/dialogs/confirm-dialog/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogModule } from '@core/dialogs/confirm-dialog/confirm-dialog/confirm-dialog.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { CoreModule } from '@core/core.module';
import { MatProgressSpinnerModule } from '@angular/material';
import { CookiesComponent } from './footer/components/cookies/cookies.component';


@NgModule({
  declarations: [
    HeaderComponent, FooterComponent, WrapperComponent, GeoSelectComponent, BreadcrumbsComponent, SearchFormComponent,
    CategoryComponent, StockPanelComponent, CookiesComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    LanguageSwitcherModule,
    IconModule,
    VerteraCommonModule,
    ButtonsModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    CheckBoxModule,
    ConfirmDialogModule,
    ClickOutsideModule,
    CoreModule,
    MatProgressSpinnerModule
  ],
  entryComponents: [ConfirmDialogComponent],
})
export class LayoutModule {
}
