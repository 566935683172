<div class="catalog" [class.opened]="showCatalog" (mouseleave)='closeCatalog()'>

  <div class="container">
    <div class="catalog--left">
      <ul class="tiny-scroll">
        <li *ngFor="let category of categories; index as grandParentIndex;"
            (mouseenter)="choseCategory(categories, grandParentIndex)"
            (click)="closeCatalog()"
            [routerLink]="category.url"
            [class.active]="category.active"
            [class.showfirst]="grandParentIndex == 0 && showFirst">
          <span>{{ category.name }}</span>
        </li>
      </ul>
    </div>

    <ng-container *ngFor="let category of categories; index as grandParentIndex;">
      <div *ngIf="category.active"
           [class.active]="category.active"
           [class.showfirst]="grandParentIndex == 0 && showFirst"
           class="catalog--right tiny-scroll">
        <div class="catalog--appointment tiny-scroll">
          <ul class="catalog--menu">
            <ng-container *ngFor="let subCategory of category?.children; index as parentIndex">

              <li class="catalog--item" *ngIf="subCategory.product_count || subCategory.children.length" [class.active]="subCategory.active">
                <a [routerLink]="subCategory.url" class="catalog--item-link"
                   (mouseenter)="choseCategory(category.children, parentIndex)"
                   (click)="closeCatalog()">
                  {{ subCategory.name | cutWords: 30 }}
                </a>

                <ul class="subcategory" *ngIf="subCategory.active">
                  <ng-container *ngFor="let subCategorySecond of subCategory?.children; index as childIndex">
                    <li class="catalog--item"
                        *ngIf="subCategorySecond.product_count || subCategorySecond.children.length" [class.active]="subCategorySecond.active">
                      <a [routerLink]="subCategorySecond.url"
                         (mouseenter)="choseCategory(subCategory.children, childIndex)"
                         (click)="closeCatalog()"
                         class="catalog--item-link">
                        {{ subCategorySecond.name }}
                      </a>

                      <ul class="subcategory second" *ngIf="subCategorySecond.active">
                        <ng-container *ngFor="let subCategoryThird of subCategorySecond?.children">
                          <li class="catalog--item"
                              *ngIf="subCategorySecond.product_count || subCategorySecond.children.length">
                            <a [routerLink]="subCategoryThird.url"
                               (click)="closeCatalog()"
                               class="catalog--item-link">
                              {{ subCategoryThird.name }}
                            </a>
                          </li>
                        </ng-container>
                      </ul>

                    </li>
                  </ng-container>
                </ul>

              </li>
            </ng-container>
          </ul>

        </div>
      </div>
    </ng-container>
  </div>
</div>
