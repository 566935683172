import { OnDestroy, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Params, Router, NavigationExtras } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { LanguageService } from '@core/services/language.service';
import { PageResult } from '@core/models/page/page.model';
import { CategoryModel } from '@core/models/store/category/category.model';
import { FilterService } from '../services/filter.service';
import { SortComponent } from '../components/sort/sort.component';
import { CategoryViewService } from '../services/category-view.service';
import { distinctUntilChanged, tap, delay } from 'rxjs/operators';
import { ProductFilter } from '@core/models';
import { StockService } from '@core/services/user/stock.service';
import { META_MESSAGES } from '@core/lib/const/messages';
import { MetaService } from '@ngx-meta/core';

export interface WithCategories {
  category_id?: number;
  category_slugs?: string;
}

export interface SortableMaybe {
  sort?: string;
  search?: string;
}

export interface InstanceModel {
  id: number;
  title: string;
  in_favorites: boolean;
}

export class PageQuery {
  per__page: number;
  page: number;
  constructor(page?: number, perPage?: number) {
    if (page) {
      this.page = +page;
    }
    if (perPage) {
      this.per__page = +perPage;
    }
  }
}

export function hasValueChanged(prev: any, next: any): boolean {
  const prevArray = Array.isArray(prev) ? prev : [prev];
  const nextArray = Array.isArray(next) ? next : [next];
  const prevCooked = prevArray.filter(v => v).map(v => v.toString());
  const nextCooked = nextArray.filter(v => v).map(v => v.toString());
  return [...prevCooked, ...nextCooked]
    .some(v => prevCooked.indexOf(v) === -1 || nextCooked.indexOf(v) === -1);
}

export type RemovableQueryParams = Params | string[] | string;

export type SortBy = 'name' | 'pv' | 'cashback' | 'price' | '-name' | '-pv' | '-cashback' | '-price' | 'hit' | '-hit' | 'new' |
 '-new' | 'rating' | 'score' | 'sort' | '-sort';

/**
 * Сам каталог не изменяет URL, только слушает его команды.
 * Конкрентые компоненты изменяют URL, например, компонент пагинации, категорий, выбор региона.
 * Алгоритм работы:
 * 1. Подпишись на изменения queryParams, routeParams.
 *    При каждом изменении выполни запрос на получение отфильтрованных экземпляров и новых фильтров, если нужно.
 * 2. Подпишись на получение экземпляров. Сохрани и отобрази их.
 * 3. Подпишись на получение фильтров. Сохрани и отобрази их.
 */
export abstract class CatalogPageComponent<I extends InstanceModel> implements OnInit, OnDestroy {
  protected constructor(
    public route: ActivatedRoute,
    public router: Router,
    protected categoryViewService: CategoryViewService,
    protected changeDetector: ChangeDetectorRef,
    protected languageService: LanguageService,
    protected filterService: FilterService,
    protected stockService: StockService,
    protected meta: MetaService
  ) {
  }

  abstract readonly perPageSet: number[];

  /**
   * Сортировка экземпляров.
   */
  abstract readonly defaultSortBy: SortBy;
  // @ts-ignore
  sortBy: SortBy = this.defaultSortBy;

  @ViewChild('searchInput', {static: false}) searchInput: ElementRef;
  @ViewChild('sortInput', {static: true}) sortInput: SortComponent;

  /**
   * Список специфичных для данной страницы значений из query params,
   * которые нужно отправить на сервер, чтобы получить отфильтрованные *экземпляры*.
   * Пример:
   * Страница может иметь в URL country_id.
   * Этим параметром управляет другой компонент (app-countries-filter и app-catalog-filter-actions);
   * Если этот параметр должен быть отправлен на сервер, добавь его pageFilters,
   * тогда он автоматически добавится в query params запроса *instancesQuery*.
   * @see onQueryParamsChange
   */
  protected abstract readonly pageFilters: string[];

  /**
   * Список специфичных для данной значений из query params,
   * которые нужно отправить на сервер, чтобы получить отфильтрованные *фильтры*.
   * Пример:
   * Страница может иметь в URL country_id.
   * Этим параметром управляет другой компонент (app-countries-filter и app-catalog-filter-actions);
   * Если этот параметр должен быть отправлен на сервер, добавь его filterFilters,
   * тогда он автоматически добавится в query params запроса *filterQuery*.
   * @see onQueryParamsChange
   */
  protected abstract readonly filterFilters: string[];

  /**
   * Функция для получения экземпляров.
   * @see getInstances
   */
  abstract instancesService;

  /**
   * Объект, члены которого отправляются на сервер в запросе экземпляров.
   */
  protected readonly abstract instancesQuery: PageQuery & WithCategories & SortableMaybe;

  protected instances: PageResult<I[]>;

  /**
   * Полный список фильтров на странице.
   * Сумма pageFilters и commonFilters.
   */
  private filters: string[];

  protected isLoadingInstances = true;
  public category: CategoryModel;

  /**
   * Нужно ли оставить на странице имеющиеся экземпляры при получении новых?
   * Используется в кнопке *показать ещё*.
   */
  public shouldKeep = false;

  /**
   * Хранит предыдущие параметры запроса фильтра.
   * Перед новой попыткой запроса проводится проверка, изменились ли фильтры фильтра или нет.
   * @see getFilters
   */
  private prevFiltersQuery: PageQuery & WithCategories;

  /**
   * Хранит предыдущие параметры запроса экземпляров.
   * Перед каждой попыткой запроса проводится проверка, изменились ли фильтры экземпляров или нет.
   * @see getInstances
   */
  private prevIntancesQuery: PageQuery & WithCategories & SortableMaybe;

  /**
   * Общие для всех каталогов фильтры экземпляров.
   */
  private readonly commonFilters = ['page', 'per__page', 'search'];

  public readonly sub = new Subscription();

  instancesReceived: Subject<void> = new Subject();

  get isLoading(): boolean {
    return this.isLoadingInstances;
  }

  ngOnInit() {
    this.firstInit();
    this.filters = this.pageFilters.concat(this.commonFilters);
    this.setUpSorting();
    this.subscribeToRouteParams();
    this.subscribeToQueryParams();
  }

  // hooks
  firstInit() {}
  mapInstanceQuery() {}
  mapFilterQuery() {}
  destroyComponent() {}

  ngOnDestroy() {
    this.destroyComponent();
    this.sub.unsubscribe();
  }

  onSearch(search: string) {
    const param = { search };
    if (search) {
      this.addQueryParams(param);
    } else {
      this.removeQueryParams(param);
    }
  }

  onSort(sort: SortBy) {
    this.addQueryParams({ sort });
  }

  protected addQueryParams(queryParams: Params, _extras: NavigationExtras = {}) {
    const main = {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
      queryParams
    };
    const extras = Object.assign(main, _extras);
    this.router.navigate([], extras);
  }

  protected removeQueryParams(queryParams: RemovableQueryParams) {
    const newParams = Object.assign({}, this.route.snapshot.queryParams);
    const keys = Array.isArray(queryParams) ? queryParams : typeof queryParams === 'string' ? [queryParams] : Object.keys(queryParams);
    for (const key of keys) {
      delete newParams[key];
    }
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: newParams,
    });
  }

  private setUpSorting() {
    const sort = this.route.snapshot.queryParams.sort || this.defaultSortBy;
    this.sortBy = sort;
    this.instancesQuery.sort = sort;
  }

  protected getCategory(slug: string) {
    const sub = this.categoryViewService.getCategoryBySlug(slug).subscribe( category => {
      if(category) {
        this.setMetaTags(category.name);
      }
      if (this.prevFiltersQuery && category && (category.slug === this.prevFiltersQuery.category_slugs)) {
        return;
      }
      this.category = category;
      this.considerCategory();
      this.getFilters();
    });
    this.sub.add(sub);
  }

  private setMetaTags(categoryName: string) {
    if(categoryName) {
      const language = this.languageService.getLanguage();
      this.meta.setTitle(META_MESSAGES.category_page_title(categoryName)[language]);
      this.meta.setTag('description', META_MESSAGES.category_page_description[language])
    }
  }

  private considerCategory() {
    const slug = this.route.snapshot.params.category_slug;
    if (slug && slug !== 'all') {
      this.instancesQuery.category_slugs = slug;
      this.filterService.filterQuery.category_slugs = slug;
    } else {
      delete this.instancesQuery.category_slugs;
      delete this.filterService.filterQuery.category_slugs;
      this.category = undefined;
    }
  }

  protected getInstances() {
    if (!this.instancesQuery.page) {
      this.instancesQuery.page = +this.route.snapshot.queryParams.page || 1;
    }
    if (!this.instancesQuery.per__page) {
      this.instancesQuery.per__page = +this.route.snapshot.queryParams.per__page || this.perPageSet[0];
    }
    this.mapInstanceQuery();
    const changed = this.prevIntancesQuery && [...this.pageFilters, 'category_slugs', 'page', 'per__page']
      .some(key => hasValueChanged(this.prevIntancesQuery[key], this.instancesQuery[key]));
    if (!changed && this.prevIntancesQuery) {
      return;
    }
    const applied = this.hasEverythingBeenApplied(
      this.pageFilters.filter(k => k !== 'sort')
    );
    if (!applied) {
      return;
    }
    // TODO: refactor all catalog to stream based logic
    if ((this.route.snapshot.params.category_slug === 'all' && this.instancesQuery.category_slugs)
        || (this.route.snapshot.params.category_slug !== 'all' && !this.instancesQuery.category_slugs)) {
      return;
    }
    if (this.instancesQuery.sort) {
      const query = Object.assign({}, this.instancesQuery);
      // remove sort param when search products until user make sort manually
      if (query.search && query.sort && !this.route.snapshot.queryParams.sort) {
        delete query.sort;
      }
      this.isLoadingInstances = true;
      const sub = this.instancesService.getInstancesWithPagination(query).subscribe(data => {
        if (data) {
          this.receiveInstances(data);
        } else {
          console.warn(data);
        }
      });
      this.sub.add(sub);
    }
    this.prevIntancesQuery = Object.assign({}, this.instancesQuery);
    this.changeDetector.detectChanges();
  }

  private getFilters() {
    this.mapFilterQuery();
    const changed = this.prevFiltersQuery && [...this.filterFilters]
    .some(key => hasValueChanged(this.prevFiltersQuery[key], this.filterService.filterQuery[key])) ||
    this.prevIntancesQuery && [...this.pageFilters, 'page', 'per__page']
      .some(key => hasValueChanged(this.prevIntancesQuery[key], this.instancesQuery[key]));

    if (!changed && this.prevFiltersQuery) {
      return;
    }
    if (this.filterService.filterQuery.country_id) {
      this.sub.add(
        this.instancesService.getFilters(this.filterService.filterQuery).pipe(
          tap((data: ProductFilter) => this.filterService.filterData$.next(data)),
          delay(10),
        ).subscribe( () => this.getInstances() )
      );
      this.prevFiltersQuery = Object.assign({}, this.filterService.filterQuery);
    }
  }

  /**
   * Проверяет, попали ли фильтры из query params в объект запроса.
   * Нужен, чтобы предотвратить лишние запросы при прямой навигации на отфильтрованную страницу каталога.
   * Например, при посещении /products/all?shop_ids=45 без проверки сначала выполнялся лишний запрос без shop_ids.
   */
  private hasEverythingBeenApplied(filters: string[]): boolean {
    const urlParams = this.route.snapshot.queryParams;
    const segmentParams = this.route.snapshot.params;
    const params = {...urlParams, ...segmentParams};
    return filters.every((filter: string) => {
       // Now we don't always have the same properties in a query object as in url params.
       // Consider this logic to be refactored
       return !!params[filter] === !!(this.instancesQuery[filter] || '').toString();
    });
  }

  private onRouteParamsChange(data: Params) {
    this.category = undefined;
    this.getCategory(data.category_slug);
  }

  private onQueryParamsChange(_params: Params) {
    const params = Object.assign({}, _params);
    if (params.page) {
      params.page = +params.page;
    }
    if (params.per__page) {
      params.per__page = +params.per__page;
    }
    for (const filter of this.filters) {
      if (params[filter]) {
        this.instancesQuery[filter] = params[filter];
      } else {
        delete this.instancesQuery[filter];
      }
    }
    for (const filter of this.filterFilters) {
      if (params[filter]) {
        this.filterService.filterQuery[filter] = params[filter];
      } else {
        delete this.filterService.filterQuery[filter];
      }
    }
    this.shouldKeep = params.keep;

    if (this.sortInput) {
      if (params.sort) {
        this.sortInput.value = params.sort;
        this.instancesQuery.sort = params.sort;
      } else {
        this.sortInput.value = this.defaultSortBy;
        this.instancesQuery.sort = this.defaultSortBy;
      }
    }
    this.considerCategory();
    this.getFilters();
  }

  protected receiveInstances(instances: PageResult<I[]>) {
    const notSame = this.instances && this.instances.pagination.currentPage !== instances.pagination.currentPage;
    if (this.shouldKeep && notSame) {
      instances.data = this.instances.data.concat(instances.data);
      this.shouldKeep = false;
    }
    this.instances = instances;
    this.isLoadingInstances = false;
    this.instancesReceived.next();
    this.changeDetector.detectChanges();
  }

  private subscribeToRouteParams() {
    const sub = this.route.params
      .pipe(distinctUntilChanged())
      .subscribe((data: Params) => {
        this.onRouteParamsChange(data);
      });
    this.sub.add(sub);
  }

  private subscribeToQueryParams() {
    const sub = this.route.queryParams
      .pipe(distinctUntilChanged())
      .subscribe((data: Params) => {
        this.onQueryParamsChange(data);
      });
    this.sub.add(sub);
  }
}
