import { GqlQuery } from 'src/app/core/lib/helpers/graph-ql/graph-ql';
import {
  InstanceModel,
  PageQuery,
  SortableMaybe,
  WithCategories,
} from '../../../catalog/abstract/catalog.model';
import { CategoryModel } from '@core/models/store/category/category.model';
import { VRStorage } from '../storage/storage.model';
import { RangeValue } from 'src/app/ui/inputs/range/range.component';
import { Tag } from 'src/app/catalog/abstract/tag';
import { CountryModel } from '../hdbk/country.model';

export enum ProductStatuses {
  published = 10,
  deactivated = 20,
  hidden = 30,
}

export interface Image {
  id: number;
  uri: string;
  thumbs: {
    a: string;
    b: string;
    c: string;
  };
}

interface ReviewAnswer {
  id: number;
  body: string;
  created_at: string;
  like_count: number;
}

export interface Review {
  id: number;
  star_count: number;
  body: string;
  created_at: string;
  like_count: number;
  reviews_answers: ReviewAnswer;
}

interface ProductDocument {
  id: number;
  uri: string;
  isImage?: boolean;
  extension?: string;
  title: string;
}

export class Product implements InstanceModel {
  id: number;
  title: string;
  images: Image[];
  slug: string;
  product_os_id: number;
  points: number;
  points_by_user: number;
  short_description: string;
  description: string;
  prices: Price[];
  price?: { [code: string]: Price };
  main_image: Image;

  is_new: boolean;
  is_hit: boolean;
  is_stock_product: boolean;
  // is_top: boolean;
  is_vegan: boolean;
  is_pro_max: boolean;
  is_halal: boolean;
  is_kosher: boolean;
  avg_rating: number;
  count_reviews: number;
  reviews: Review[];
  in_cart: boolean;
  in_favorites: boolean;

  weight_netto: number;
  weight_brutto: number;
  price_customer: number;
  price_partner: number;
  currency: string;
  in_cart_count: number;
  status: {
    id: number;
    name: string;
  };
  vendor_code: number;
  composition: string;
  documents: ProductDocument[];
  use: string;
  related: Product[];
  categories: CategoryModel[];
  tags: Tag[];
  stock: {
    id: number;
    name: string;
    description: string;
  };
}

export class Price {
  currency_code: string;
  price_partner: number;
  price_customer: number;
}

export class ProductQuery
  extends PageQuery
  implements SortableMaybe, WithCategories {
  ids?: string;
  product_os_ids?: string;
  storage_id?: number;
  slugs?: string;
  category_slugs?: string;
  category_id?: number;
  sort?: string;
  search?: string;
  country_id?: number;
  tag_ids?: string;
  is_partner_selection?: boolean;
}

export class ProductIds {
  constructor(public productIds: number[]) {
    // [1,2,3...)
  }

  public get product_ids() {
    return this.productIds.join(',');
  }
}

export class ProductsFilterRequest extends PageQuery {
  ids?: string;
  product_os_ids?: string;
  category_slugs?: string;
  storage_ids?: string;
  country_id?: string;
  region_ids?: string;
  city_id?: string;
  tag_ids?: string;
  constructor() {
    super();
  }
}

export class ProductFilter {
  price: RangeValue;
  categories: CategoryModel[];
  geos: CountryModel[];
  tags: Tag[];
  selected_categories: CategoryModel[];
  selected_storages: VRStorage[];
  selected_tags: Tag[];
}

export interface FilterCount {
  count: number;
}

export const gqlProductMinFields = `
  id
  slug
  title
  main_image {
    id
    uri
    thumbs
  }
  status
  price_customer
  price_partner
  currency
  in_cart
  in_favorites
  in_cart_count
  is_new
  is_hit
  is_vegan
  is_pro_max
  is_halal
  is_kosher
  is_stock_product
  points_by_user
  weight_brutto
  tags {
    id
    name
  }
`;

export const gqlProductFields = `{
  ${gqlProductMinFields}
  images {
    id
    uri
  }
  product_os_id
  points
  short_description
  description
  avg_rating
  count_reviews
  reviews
  weight_netto
  weight_brutto
  status
  vendor_code
  composition
  stock
  documents {
    id
    uri
    title
  }
  use
  related {
    ${gqlProductMinFields}
  }
  categories {
    id
    slug
    name
  }
}`;

export const gqlProductFilterFields = `{
  price
  categories
  geos
  tags
  selected_categories
  selected_storages
  selected_tags
}`;

export const gqlCitiesFilterFields = `{
  cities
}`;

export const gqlFilterCountFields = `{
  count
}`;

export class GqlProductQuery extends GqlQuery {
  fields = gqlProductFields;
}

export class GqlProductsQuery extends GqlQuery {
  fields = `{ ${gqlProductMinFields} }`;
}

export class GqlProductFiltersQuery extends GqlQuery {
  fields = gqlProductFilterFields;
}
export class GqlCitiesFiltersQuery extends GqlQuery {
  fields = gqlCitiesFilterFields;
}

export class GqlFilteCountsQuery extends GqlQuery {
  fields = gqlFilterCountFields;
}

export const SLUGS_TO_EXCLUDE = [
  'premium-miracle',
  'premium-sensation',
  'booster-sleepslim',
  'booster-sportpower',
  'plasmamax',
  'artroplast',
  'set-marathon-vip-il-01',
  'set-marathon-start-il-01',
  'set-setterspack-04',
  'set-setterspack-05',
  'vlab-dk-complex',
  'vlab-zinc-picolinat',
  'vlab-chrom-picolinat',
  'vlab-vitamin-c',
  'vlab-mag-b6',
  'vlab-cordyceps',
  'vlab-organic-iodine',
  'vlab-antihelmix',
  'vlab-hepa-normix',
  'vlab-arthro-normix',
  'vlab-visio-normix',
  'vlab-balance-normix',
  'set-plaasma-pack-01',
  'vertera-gel-liof-01',
  'vertera-forte-liof-01',
  'angiolive-liof-01'
];
