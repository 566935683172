import { environment } from 'src/environments/environment';
import { Directive, Input, ElementRef, Renderer2 } from '@angular/core';


@Directive({
  selector: '[appStatic]'
})
export class StaticDirective {
  @Input() staticSrcset: string;

  @Input() set appStatic(url: string) {
    const link = `${environment.staticUrl}${url}`;

    switch (this.el.nativeElement.tagName) {
      case 'IMG': {
        this.el.nativeElement.src = link;
        if (this.staticSrcset) {
          const newSrcset = this.staticSrcset
            .split(',')
            .map((src: string) => src.replace('/assets', `${environment.staticUrl}/assets`))
            .join(',');
          setTimeout(() => {
            this.renderer.setAttribute(this.el.nativeElement, 'secset', newSrcset);
          }, 1);
        }
        break;
      }
      case 'A': {
        this.el.nativeElement.href = link;
        break;
      }
      case 'SOURCE': {
        this.el.nativeElement.srcset = link;
        break;
      }
      case 'DIV': {
        this.renderer.setAttribute(this.el.nativeElement, 'style', `background-image: url('${link}')`);
        break;
      }
      default: {
        this.el.nativeElement.src = link;
      }
    }
  }

  constructor(private el: ElementRef, private renderer: Renderer2) { }
}
