import { GqlQuery } from '@core/lib/helpers/graph-ql/graph-ql';

export interface CategoryModel {
  id: number;
  name: string;
  depth: number;
  product_count: number;
  url?: string;
  slug: string;
  active?: boolean;
  count?: number;
  selected?: boolean;
  hasSelectedChild?: boolean;
  // meta_title?: string;
  // meta_description?: string;
  // meta_keywords?: string;
}

export const gqlCategoryFields = `{
  id
  name
  slug
  depth
  product_count
}`;

export interface CategoryGrandParentModel extends CategoryModel {
  depth: 1;
  children: CategoryParentModel[];
}

export interface CategoryParentModel extends CategoryModel {
  parent: CategoryGrandParentModel;
  children: CategoryParentDepth3Model[];
  depth: 2;
}

export interface CategoryParentDepth3Model extends CategoryModel {
  parent: CategoryParentModel;
  children: CategoryChildModel[];
  depth: 3;
}

export interface CategoryChildModel extends CategoryModel {
  parent: CategoryParentDepth3Model;
  depth: 4;
}

export class CategoryQueryModel {
  id: number;
  name: string;
  slug: string;
  depth: string;
  parent_id: number;

  constructor(public country_id: number = 113) {
  }
}

export class GqlCategoryQuery extends GqlQuery {
  fields = gqlCategoryFields;
}
