import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { ConfirmDialigInterface, ConfirmDialogComponent } from '@core/dialogs/confirm-dialog/confirm-dialog/confirm-dialog.component';
import { AZERBAIJAN_REDIRECT_DIALOG, BG_REDIRECT_DIALOG, CS_REDIRECT_DIALOG, DE_REDIRECT_DIALOG, ES_REDIRECT_DIALOG, EU_REDIRECT_DIALOG,
  RU_REDIRECT_DIALOG,
   TURKEY_REDIRECT_DIALOG } from '@core/lib/const/messages';
import { CountryModel } from '@core/models/hdbk/country.model';
import { Observable, of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LanguageService } from '../language.service';
import { RedirectService } from '../redirectService';

@Injectable({
  providedIn: 'root'
})
export class CountryRedirectService {

  constructor(private languageService: LanguageService, private dialog: MatDialog,
    private route: ActivatedRoute, private redirectService: RedirectService) { }

  checkTurkey(): Observable<boolean> {
    return this.checkCountry(TURKEY_REDIRECT_DIALOG, this.addRefCode(environment.turkey.url));
  }

  checkAzerbaijan(): Observable<boolean> {
   return this.checkCountry(AZERBAIJAN_REDIRECT_DIALOG, this.addRefCode(environment.azerbaijan.url));
  }

  checkEU(country: CountryModel): Observable<boolean> {
    let lang = this.languageService.getLanguage();
    lang = lang === 'ru' ? 'en' : lang;
    let REDIRECT_DIALOG_TEXT: any;
    if (lang === 'cs') {
      REDIRECT_DIALOG_TEXT = CS_REDIRECT_DIALOG;
    } else if (lang === 'ru') {
      REDIRECT_DIALOG_TEXT = RU_REDIRECT_DIALOG;
    } else if (lang === 'bg') {
      REDIRECT_DIALOG_TEXT =BG_REDIRECT_DIALOG;
    } else if (lang === 'es') {
      REDIRECT_DIALOG_TEXT = ES_REDIRECT_DIALOG;
    } else if (lang === 'de') {
      REDIRECT_DIALOG_TEXT = DE_REDIRECT_DIALOG;
    } else {
      REDIRECT_DIALOG_TEXT = EU_REDIRECT_DIALOG;
    }
    const dialogText = Object.assign({}, REDIRECT_DIALOG_TEXT);
    dialogText.question = dialogText.question.replace('{country}', country.name);
    const redirectTo = this.buildRedirectUrl(country);
    return this.checkCountry(dialogText, redirectTo);
  }

  private checkCountry(dialogText: ConfirmDialigInterface, redirectTo: string): Observable<boolean> {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      width: '300px',
      data: dialogText,
    });
    return dialog.afterClosed().pipe(take(1),mergeMap((accept: boolean) => {
      if(accept) {
        this.redirectService.redirectToUrl(redirectTo, true);
      }
      return of(accept);
    }));
  }

  private buildRedirectUrl(country: CountryModel): string {
    const lang = this.languageService.getLanguage();
    let url = `${environment.eu.url}${lang === 'ru'? 'en' : lang }/geo-lang?country_id=${country.id}`;
    url = this.addRefCode(url);
    return url;
  }

  private addRefCode(url: string): string {
    const refCode = this.route.snapshot.queryParams.bonus;
    if(refCode) {
      if(url.includes('?')) {
        url = `${url}&bonus=${refCode}`;
      } else {
        url = `${url}?bonus=${refCode}`;
      }
    }
    return url;
  }
}
