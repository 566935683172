import { Injectable, Inject, PLATFORM_ID, Injector } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthTokenService } from '@core/services/auth/auth-token.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '@core/services/auth/auth.service';
import { UserHelperService } from '@core/services/user-helper.service';
import { UserService } from '@core/services';

@Injectable()
export class AuthGuard implements CanActivate {
  token: boolean;
  constructor(
    private auth: AuthService,
    @Inject(PLATFORM_ID) public platform: any,
    private userHelper: UserHelperService,
    private userSerivce: UserService,
    private injector: Injector,
  ) {
    this.token = !!this.injector.get(AuthTokenService).getAuthHeader();
  }

  canActivate(): Observable<boolean> | boolean {
    if (!this.userSerivce.user) {
      return this.userSerivce.getUser().pipe(
        map(user => this.userGuardMap(user))
      );
    } else {
      return this.userHelper.user$.pipe(
        map(user => this.userGuardMap(user))
      );
    }
  }

  userGuardMap(user) {
    let isCanctivate: boolean = true;
    if (!this.token) {
      isCanctivate = false;
      this.auth.externalLogin();
    }
    if (!user) {
      isCanctivate = false;
      this.auth.externalLogin();
    }
    return isCanctivate;
  }
}
