<div class="stock__panel">
  <div class="stock__panel--header">
    <div class="stock__panel--header-title" i18n>Stocks</div>
    <div class="stock__panel--header-text" i18n="@@app-stock-points">Points in cart:</div>
    <div class="stock__panel--header-number"> {{ cartViewService.points | number:'1.0-2' }} </div>
    <div class="stock__panel--header-icon">
      <img appStatic="/assets/img/cart/y-points.svg"/>
    </div>
    <div class="stock__panel--header-close">
      <svg-icon name="control--close" size="12" (click)="close()"></svg-icon>
    </div>
  </div>
  <!-- <div class="stock__panel--check-box">
    <app-check-box [medium]="true">
      <span class="stock__panel--check-box-lable" i18n="@@app-stock-check-box">
        Show only stock items
      </span>
    </app-check-box>
  </div> -->
  <div class="stock__panel--choose" *ngIf="stocks.length > 0">
    <svg-icon name="control--info" size="16"></svg-icon>
    <span i18n="@@app-stock-footer-text">Choose one of the shares</span>
  </div>
  <div class="stock__panel--body tiny-scroll" [class.empty]="stocks.length == 0">
    <ng-container *ngIf="stocks.length > 0">
      <div class="stock__item" *ngFor='let stock of stocks; let i=index' [ngClass]= '{
      blue: (i+1) % 2 !== 0 && (i+1) % 3 !== 0,
      yellow: (i+1) % 2 === 0,
      purple: (i+1) % 3 === 0
    }'>
        <div class="stock-loader" >
          <mat-spinner *ngIf='stock.isLoading' class='dialog-block-spinner' [diameter]='20'></mat-spinner>
        </div>
        <div class="stock__item--header">
          <div class="stock__item--name">
            {{ stock.name }}
          </div>
          <div class="stock__item--text" [innerHTML]="stock.description">
          </div>
        </div>
        <div class="stock__item--footer">
          <div class="stock__item--row">
            <span class="stock__item--row--lable" i18n="@@app-stock-validity">Promotion period:</span>
            <span class="stock__item--row--text" *ngIf="stock.start_at && stock.end_at" i18n='@@stocks.from_to'>From {{ stock.start_at | date }} to {{stock.end_at | date }}</span>
            <span class="stock__item--row--text" *ngIf="!stock.start_at && !stock.end_at" i18n='@@stocks.not_limited_msg'>Validity period is not limited</span>
            <span class="stock__item--row--text" *ngIf="stock.start_at && !stock.end_at" i18n='@@stocks.not_limited_msg_from'>Validity period is not limited from {{ stock.start_at | date }}</span>
            <span class="stock__item--row--text" *ngIf="!stock.start_at && stock.end_at" i18n='@@stocks.valid_until'> Valid until {{ stock.end_at | date }} </span>
          </div>
          <!-- <div class="stock__item--row">
            <div class="stock__item--row--lable" i18n="@@app-stock-valid-for">The promotion is valid for:</div>
            <div class="stock__item--row--text">Все товары Vertera</div>
          </div> -->
          <div class="stock__item--row">
            <app-transparent-button [pink]="true" height="h32" [disabled]="stock.is_active">
              <span *ngIf="stock.is_active" i18n="@@app-stock-activated">Activated</span>
              <span *ngIf="!stock.is_active" (click)="selectStock(stock)" i18n="@@app-stock-activate">Activate</span>
            </app-transparent-button>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="stocks.length == 0">
      <svg-icon name="ic--calendar" size="40"></svg-icon>
      <div class="stock__panel--empty-text" i18n="@@app-stock-empty-text">There are no active stocks today.</div>
    </ng-container>
  </div>
</div>
