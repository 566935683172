<div class="modal-content">
    <span class="modal__close" (click)="onClose()"></span>
    <h3 class="title">
      <span *ngIf="type ==='from-fav'">
      <ng-container *ngIf="entityType ==='product'" i18n>
        Item removed from wish list
      </ng-container>
    </span>
      <span *ngIf="type ==='to-fav'">
      <ng-container *ngIf="entityType ==='product'" i18n>
        Item added to wish list
      </ng-container>
    </span>
      <span *ngIf="type ==='to-cart'">
      <ng-container *ngIf="entityType ==='product'" i18n>
        Item added to cart
      </ng-container>
    </span>
    </h3>
    <div class="modal-body" routerLink="/product/{{ variant.slug }}">
        <img *ngIf="variant.main_image?.uri" [src]="variant.main_image?.thumbs.a" [alt]="variant.title">
        <div class="description">
          <p class="name">{{ variant.title | cutWords: 35 : 'hard' }}</p>
          <div class="bottom" *ngIf="entityType ==='product'">
            {{ variant.in_cart_count }}&nbsp;<span i18n="@@count_pcs">pcs</span>
          </div>
        </div>
        <p class="price" *appIfAuth>{{ variant.price_partner | currency: variant.currency :'symbol':'1.0-2' }}</p>
        <p class="price" *appIfAuth="'not'">{{ variant.price_customer | currency: variant.currency :'symbol':'1.0-2' }}</p>
    </div>
    <app-green-button
      *ngIf="type == 'to-cart'"
      style="width: 100%"
      routerLink="/cart"
      (click)="onClose()"
      transform="uppercase"
      [style]="{'justify-content':'center'}" i18n>
        Go to cart
    </app-green-button>
    <ng-container *appIfAuth>
      <app-notifier-stock-info  *ngIf="type ==='to-cart'"></app-notifier-stock-info>
    </ng-container>
    <app-green-button
      *ngIf="type == 'to-fav' || type == 'from-fav'"
      style="width: 100%"
      routerLink="/lk/customer/favorites/product-variant"
      (click)="onClose()"
      transform="uppercase"
      [style]="{'justify-content':'center'}" i18n>
        Go to your wish list
    </app-green-button>

</div>
