import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BASE_URL } from '@core/lib/api.factory';
import { Observable, BehaviorSubject } from 'rxjs';
import { GqlStockQuery, Stock, StockSelectQuery, StockQuery } from '@core/models/user/stock.model';
import { map, tap } from 'rxjs/operators';
import { CityService } from '../hdbk/city.service';

@Injectable()
export class StockService {

  stocksUpdated$ = new BehaviorSubject(null);
  activeStock$ = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private cityService: CityService,
    @Inject(BASE_URL) private baseUrl: string,
  ) { }

  getUserStocks(): Observable<Stock[]> {
    const query = new StockQuery();
    query.city_id = this.cityService.getCityId();
    const gqlQuery = new GqlStockQuery(query);
    return  this.http.get<{data: Stock[]}>(
      `${this.baseUrl}/user/stock?${gqlQuery.toRest()}`)
        .pipe(map(res => res.data), tap((stocks: Stock[]) => {
          const activeStock: Stock = stocks.find((stock) => stock.is_active);
          this.activeStock$.next(activeStock);
        }));
  }

  selectStock(query: StockSelectQuery): Observable<any> {
    return this.http.put(`${this.baseUrl}/user/stock/select`, query).
    pipe( tap(() => this.stocksUpdated$.next(query.user_stock_id)));
  }
}
