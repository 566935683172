<div class="dialog__content">
  <div [innerHTML]="text"></div>
  <div class="dialog__buttons">
    <button class="dialog__btn" *ngIf="!accept"
            (click)="close(true)" i18n>Yes</button>
    <button class="dialog__btn" *ngIf="accept"
            (click)="close(true)">{{ accept }}</button>
    <button class="dialog__btn" *ngIf="!deny"
            (click)="close()" i18n>No</button>
    <button class="dialog__btn" *ngIf="deny"
            (click)="close()">{{ deny }}</button>
  </div>
</div>
