import { ConfirmDialigInterface } from '@core/dialogs/confirm-dialog/confirm-dialog/confirm-dialog.component';

export const FAV_DELETE_MESSAGE = {
  ru: 'Вы действительно хотите удалить товар из избранного?',
  en: 'Are you sure you want to remove the product from your favorites?',
  bg: 'Сигурни ли сте, че искате да премахнете елемента от любимите си?',
  cs: 'Opravdu chcete odebrat položku ze svých oblíbených položek?',
  de: 'Möchten Sie den Artikel wirklich aus Ihren Favoriten entfernen?',
  es: 'Estás seguro de que deseas eliminar el elemento de tus favoritos?',
  tr: 'Öğeyi favorilerinizden kaldırmak istediğinizden emin misiniz?'
};

export const TURKEY_REDIRECT_DIALOG: ConfirmDialigInterface = {
  question: 'Ülke olarak "Türkiye" seçtiğinizde sizi Türkiye alışveriş sayfasına önlendiriyoruz',
  accept: 'Devam et',
  deny: 'Iptal et',
};

export const AZERBAIJAN_REDIRECT_DIALOG: ConfirmDialigInterface = {
  question: 'Azərbaycanı seçərkən sizi Azərbaycanda satınalmalar üçün veb sayta yönləndiririk',
  accept: 'Davam et',
  deny: 'Ləğv et',
};

export const CS_REDIRECT_DIALOG: ConfirmDialigInterface = {
  question: 'Když vyberete zemi {country}, přesměrujeme vás na web pro nakupování v Vertera Evropě.',
  accept: 'Pokračovat',
  deny: 'Storno',
};

export const RU_REDIRECT_DIALOG: ConfirmDialigInterface = {
  question: 'При выборе страны {country} мы перенаправляем вас на сайт для покупок в Вертера Европа',
  accept: 'Продолжить',
  deny: 'Отмена',
};

export const BG_REDIRECT_DIALOG: ConfirmDialigInterface = {
  question: 'Когато изберете държава {country}, ние ви пренасочваме към сайта за пазаруване в Europe',
  accept: 'Продължи',
  deny: 'Отказ',
};

export const ES_REDIRECT_DIALOG: ConfirmDialigInterface = {
  question: 'Cuando selecciona un país {country} le redirigimos al sitio de compras en Vertera Europa',
  accept: 'Continuar',
  deny: 'Cancelación',
};

export const DE_REDIRECT_DIALOG: ConfirmDialigInterface = {
  question: 'Wenn Sie ein Land auswählen {country}, leiten wir Sie zum Einkaufen in Vertera Europe auf die Website weiter.',
  accept: 'Fortfahren',
  deny: 'Abbrechen',
};

export const EU_REDIRECT_DIALOG: ConfirmDialigInterface = {
  question: 'When you select a country {country} we will redirect you to the site for shopping in Vertera Europe',
  accept: 'Continue',
  deny: 'Cancel',
};

export const LINK_COPIED_MESSAGE = {
  ru: 'Ссылка скопирована',
  en: 'The link was copied',
  bg: 'Връзката е копирана',
  cs: 'Odkaz byl zkopírován',
  de: 'Link kopiert',
  es: 'Enlace copiado',
  tr: 'Bağlantı kopyalandı'
};

export const ADDRESS = {
  house: {
    ru: 'дом',
    en: 'house',
    bg: 'къща',
    cs: 'dům',
    de: 'haus',
    es: 'casa',
    tr: 'ev'
  },
  build_block: {
    ru: 'корп./стр',
    en: 'building/structure',
    bg: 'сграда/структура',
    cs: 'budova/struktura',
    de: 'körper/struktur',
    es: 'estructura/construcción',
    tr: 'bina/yapısı'
  },
  flat: {
    ru: 'кв./офис',
    en: 'apartment/office',
    bg: 'апартаменти/офис',
    cs: 'bytová/kancelář',
    de: 'eben/büro',
    es: 'plano/oficina',
    tr: 'düz/ofis'
  },
  zip_code: {
    ru: 'индекс',
    en: 'zip code',
    bg: 'пощенски код',
    cs: 'PSČ',
    de: 'PLZ',
    es: 'código postal',
    tr: 'posta kodu'
  }
};

export const META_MESSAGES = {
  main_page_title: {
    ru: 'Vertera - продукция для красоты и здоровья из ламинарии',
    en: 'Vertera - health and beauty products from kelp',
    bg: 'Vertera - продукти за здраве и красота от водорасли',
    cs: 'Vertera - výrobky pro zdraví a krásu z řasy',
    de: 'Vertera - Gesundheits- und Schönheitsprodukte von Seetang',
    es: 'Vertera - productos de salud y belleza de kelp',
    tr: 'Vertera - kelp\'ten sağlık ve güzellik ürünleri'
  },
  main_page_description: {
    ru: 'Vertera – это водорослевое питание с органическим йодом  и  косметики для обертывания со 100% натуральным составом. Скидка 30% после регистрации!',
    en: 'Vertera is an algal food with organic iodine and wrap cosmetics with a 100% natural composition. 30% discount after registration!',
    bg: 'Vertera е водорасла храна с органичен йод и обвиваща козметика със 100% натурален състав. 30% отстъпка след регистрация!',
    cs: 'Vertera je jídlo z řas s organickým jódem a kosmetika se 100% přírodním složením. 30% sleva po registraci!',
    de: 'Vertera ist ein Algenfutter mit Bio-Jod und Wrap-Kosmetik mit einer 100% natürlichen Zusammensetzung. 30% Rabatt nach Anmeldung!',
    es: 'Vertera es un alimento de algas con yodo orgánico y cosméticos envolventes con una composición 100% natural. ¡30% de descuento después del registro!',
    tr: 'Vertera, organik iyotlu bir alg gıdasıdır ve% 100 doğal bir bileşime sahip sargı kozmetikler. Kayıttan sonra% 30 indirim!'
  },
  delivery_page_title: {
    ru: 'Доставка продукции Vertera',
    en: 'Vertera Product Delivery',
    bg: 'Доставка на продукт Vertera',
    cs: 'Dodávka produktu Vertera',
    de: 'Vertera Produktlieferung',
    es: 'Entrega de productos Vertera',
    tr: 'Vertera Ürün Teslimi'
  },
  delivery_page_description: {
    ru: 'Узнайте подробнее о способах доставки любимых продуктов из магазина Vertera',
    en: 'Learn more about shipping options for your favorite products from the Vertera store',
    bg: 'Научете повече за опциите за доставка на любимите си продукти от магазина на Vertera',
    cs: 'Další informace o možnostech dopravy vašich oblíbených produktů z obchodu Vertera',
    de: 'Weitere Informationen zu den Versandoptionen für Ihre Lieblingsprodukte erhalten Sie im Vertera Store',
    es: 'Obtenga más información sobre las opciones de envío de sus productos favoritos en la tienda Vertera',
    tr: 'Vertera mağazasından sevdiğiniz ürünlerin gönderim seçenekleri hakkında daha fazla bilgi edinin'
  },
  category_page_title: (category: string) => {
    return {
      ru: `${category} в магазине Vertera`,
      en: `${category} at Vertera Store`,
      bg: `${category} в магазин Vertera`,
      cs: `${category} v obchodě Vertera`,
      de: `${category} im Vertera Store`,
      es: `${category} en la Tienda Vertera`,
      tr: `Vertera Store'da ${category}`
    }
  },
  category_page_description: {
    ru: 'Официальный сайт компании Vertera: каталог продукции. Водорослевое питание и косметика, домашнее SPA, продукты для иммунитета.',
    en: 'Vertera official website: product catalog. Algae nutrition and cosmetics, home SPA, immunity products.',
    bg: 'Официален сайт на Vertera: каталог на продукти. Хранене и козметика за водорасли, домашен SPA, продукти за имунитет.',
    cs: 'Oficiální stránky Vertera: katalog produktů. Výživa a kosmetika řas, domácí SPA, produkty imunity.',
    de: 'Offizielle Website von Vertera: Produktkatalog. Algenernährung und Kosmetik, Home SPA, Immunitätsprodukte.',
    es: 'Sitio web oficial de Vertera: catálogo de productos. Nutrición y cosmética de algas, SPA casero, productos de inmunidad.',
    tr: 'Vertera resmi web sitesi: ürün kataloğu. Yosun beslenme ve kozmetik, ev SPA, bağışıklık ürünleri.'
  },
  product_page_description: (product: string) => {
   return {
      ru: `${product}: описание продукта, инструкция по применению, цена и отзывы на официальном сайте Vertera`,
      en: `${product}: product description, instructions for use, price and reviews on the official website of Vertera`,
      bg: `${product}: описание на продукта, инструкции за употреба, цена и отзиви на официалния уебсайт на Vertera`,
      cs: `${product}: popis produktu, návod k použití, cena a recenze na oficiálních stránkách Vertera`,
      de: `${product}: Produktbeschreibung, Gebrauchsanweisung, Preis und Bewertungen auf der offiziellen Website von Vertera`,
      es: `${product}: descripción del producto, instrucciones de uso, precio y comentarios en el sitio web oficial de Vertera`,
      tr: `${product}: Vertera resmi web sitesinde ürün açıklaması, kullanım talimatları, fiyat ve yorumlar`
    }
  },
  production_page_description: {
    ru: 'Vertera – это водорослевое питание с органическим йодом и косметика со 100% натуральным составом. Скидка 30% после регистрации!',
    en: 'Vertera is a seaweed nutrition with organic iodine and cosmetics with 100% natural composition. 30% discount after registration!',
    bg: 'Vertera е хранене с водорасли с органичен йод и козметика със 100% естествен състав. 30% отстъпка след регистрация!',
    cs: 'Vertera je výživa mořských řas s organickým jódem a kosmetikou se 100% přírodním složením. 30% sleva po registraci!',
    de: 'Vertera ist eine Algenernährung mit Bio-Jod und Kosmetika mit 100% natürlicher Zusammensetzung. 30% Rabatt nach Anmeldung!',
    es: 'Vertera es una nutrición de algas con yodo orgánico y cosmética con composición 100% natural. ¡30% de descuento después del registro!',
    tr: 'Vertera, organik iyot ve% 100 doğal bileşime sahip kozmetikler içeren bir deniz yosunu besinidir. Kayıttan sonra% 30 indirim!',
    az: 'Vertera,% 100 təbii tərkibli üzvi yod və kosmetik məhsullarla yosun qidasıdır. Qeydiyyatdan sonra 30% endirim!'
  }
};

export const FILTER_OPTIONS = {
  all: {
    ru: 'Все',
    en: 'All',
    bg: 'Всичко',
    cs: 'Všechno',
    de: 'Alles',
    es: 'Todas',
    tr: 'Herşey',
    az: 'Hamısı'
  },
  yes: {
    ru: 'Да',
    en: 'Yes',
    bg: 'Да',
    cs: 'Ano',
    de: 'Ja',
    es: 'Si',
    tr: 'Evet',
    az: 'Bəli'
  },
  no: {
    ru: 'Нет',
    en: 'No',
    bg: 'Не',
    cs: 'Ne',
    de: 'Nein',
    es: 'No',
    tr: 'Değil',
    az: 'Yox'
  }
}

export const BPV_AND_HIGHER_MSG = {
  ru: '50 BPV и выше',
  en: '50 BPV and higher',
  cs: '50 BPV a výše',
  bg: '50 BPV и повече',
  es: '50 BPV y más',
  tr: '50 BPV ve üstü',
  az: '50 BPV və daha yüksək'
}

export const BPV_FIRST_RANK = {
  ru: 'BPV в первой линии',
  en: 'BPV in the first rank',
  cs: 'BPV V prvním řádku je',
  bg: 'BPV на първа линия',
  es: 'BPV en la primera línea',
  tr: 'BPV İlk satırd',
  az: 'BPV birinci xəttdə'
}
