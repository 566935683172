import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  CategoryChildModel,
  CategoryGrandParentModel,
  CategoryModel, CategoryParentDepth3Model,
  CategoryParentModel
} from '@core/models/store/category/category.model';
import { CategoryService } from '@core/services/category/category.service';
import { Subscription } from 'rxjs';
import { CityService } from '@core/services/hdbk/city.service';


@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: [ './category.component.scss' ]
})
export class CategoryComponent implements OnInit, OnDestroy {
  readonly sub$: Subscription = new Subscription();
  showFirst = true;
  showCatalog: boolean;
  categories: CategoryGrandParentModel[];

  constructor(
    private categoryService: CategoryService,
    private cityService: CityService
  ) {
  }

  ngOnInit() {
    this.subscribeToCityChange();
  }
  ngOnDestroy() {
    this.sub$.unsubscribe();
  }

  choseCategory(categories: CategoryGrandParentModel[] | CategoryParentModel[] | CategoryParentDepth3Model[] | CategoryChildModel[],
                index: number
  ) {
    categories.forEach(c => c.active = false);
    categories[ index ].active = true;
    this.showFirst = false;
  }

  toggleCatalog() {
    this.showCatalog = !this.showCatalog;
  }

  closeCatalog() {
    this.showCatalog = false;
  }

  openCatalog() {
    this.showCatalog = true;
  }

  subscribeToCityChange() {
    const sub = this.cityService.cityChange$.subscribe(() => {
      this.getCategories();
    });
    this.sub$.add(sub);
  }

  buildTreeFromArray(arr: CategoryModel[]): CategoryGrandParentModel[] {
    // arr = arr.sort((a, b) => a.depth - b.depth);
    const result: CategoryGrandParentModel[] = [];
    let lastGrandParent: CategoryGrandParentModel;
    let lastParent: CategoryParentModel;
    let lastParentDepth3: CategoryParentDepth3Model;
    for (const category of arr) {
      const url = `/catalog/${category.slug}`;
      if (category.depth === 1) {
        const grandParentCategory = Object.assign({children: [], url}, category) as CategoryGrandParentModel;
        result.push(grandParentCategory);
        lastGrandParent = grandParentCategory;
      } else if (category.depth === 2) {
        const parentCategory = Object.assign({children: [], parent: lastGrandParent, url}, category) as CategoryParentModel;
        if (lastGrandParent) {
          lastGrandParent.children.push(parentCategory);
          lastParent = parentCategory;
        }
      } else if (category.depth === 3) {
        const parentDepth3Category = Object.assign({children: [], parent: lastParent, url}, category) as CategoryParentDepth3Model;
        lastParent.children.push(parentDepth3Category);
        lastParentDepth3 = parentDepth3Category;
      } else if (category.depth === 4) {
        const childCategory = Object.assign({parent: lastParentDepth3, url}, category) as CategoryChildModel;
        lastParentDepth3.children.push(childCategory);
      }
    }
    this.choseCategory(result, 0);
    return result;
  }

  private getCategories() {
    const sub = this.categoryService.getCategories().subscribe(categories => {
      this.categories = this.buildTreeFromArray(categories);
    });
    this.sub$.add(sub);
  }
}
