import { LanguageService } from './language.service';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { filter, map, take, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AppStorage } from '../storage/universal.inject';
import { isPlatformBrowser } from '@angular/common';
import { CityModel, CityQueryModel } from '@core/models/geo/city.model';
import { DynamicScriptLoaderService } from '.';
import { CityService } from './hdbk/city.service';
import { LANG_ID_MAPS } from '@core/lib/const/langs';
import { Params } from '@angular/router';

declare var ymaps: any;

@Injectable({
  providedIn: 'root'
})
export class GeoService {
  myCityName: string;
  myCity: CityModel;

  defaultCities: any = {
    en: [
      { name: 'Moscow', country_id: 113 },
      { name: 'Kiev', country_id: 5 },
      { name: 'Prague', country_id: 331 },
      { name: 'Sofia', country_id: 21 },
      { name: 'Nur-Sultan', country_id: 40 },
      { name: 'London', country_id: 297 },
      { name: 'Yerevan', country_id: 13 },
      { name: 'Rome', country_id: 187 },
      { name: 'Vienna', country_id: 115 },
      { name: 'Copenhagen', country_id: 169 },
      { name: 'Tbilisi', country_id: 28 },
      { name: 'Berlin', country_id: 160 },
      { name: 'Paris', country_id: 323 },
      { name: 'Helsinki', country_id: 321 },
    ],
    ru: [
      { name: 'Москва', country: { id: 113 } },
      { name: 'Санкт-Петербург', country: { id: 113 } },
      { name: 'Новосибирск', country: { id: 113 } },
      { name: 'Екатеринбург', country: { id: 113 } },
      { name: 'Нижний Новгород', country: { id: 113 } },
      { name: 'Краснодар', country: { id: 113 } },
      { name: 'Челябинск', country: { id: 113 } },
      { name: 'Кемерово', country: { id: 113 } },
      { name: 'Тюмень', country: { id: 113 } },
      { name: 'Красноярск', country: { id: 113 } },
      { name: 'Казань', country: { id: 113 } },
      { name: 'Пермь', country: { id: 113 } },
    ]
  };

  previousUrl: string;
  previousQueryParams: Params;

  get myCityNameSelected(): boolean {
    return !!this.myCityName;
  }

  constructor(
    @Inject(AppStorage) private appStorage: Storage,
    private dynamicScriptLoader: DynamicScriptLoaderService,
    private cityService: CityService,
    private languageService: LanguageService,
    @Inject(PLATFORM_ID) private platform: any
  ) {
    this.getMyCityName();
  }

  getMyCityName(): string {
    if (!this.myCityName) {
      const city = this.cityService.getCity();
      this.myCityName = city ? city.name : '';
    }
    return this.myCityName;
  }

  getMyCity(): Observable<CityModel> {
    if (this.myCity) {
      return of(this.myCity);
    } else {
      const query = new CityQueryModel();
      query.search = this.getMyCityName();
      return this.cityService.getPage(query)
        .pipe(
          filter( data => !!data.data[0]),
          take(1),
          map( data => {
            this.myCity = new CityModel(data.data[0].id, this.myCityName);
            this.myCity.country = data.data[0].country;
            return this.myCity;
          })
        );
    }
  }

  detectGeo(): Observable<{name: string}> {
    if (!isPlatformBrowser(this.platform)) {
      return;
    }
    const geo = { name: this.getMyCityName()};

    if (!geo.name) {
      // tslint:disable-next-line: deprecation
      return Observable.create( observer => {
        this.loadDetectScript().then(() => {
          ymaps.ready(() => {
            this.cityService.setCity(ymaps.geolocation.city);
            observer.next({ name: ymaps.geolocation.city });
          });
        }).catch(error => console.error(error));

      });
    } else {
      return of(geo);
    }
  }
  // Загрузка только по необходимости, если не выбран город
  loadDetectScript(): Promise<any> {
    let langQuery = `&lang=ru-RU`;
    const property = LANG_ID_MAPS[this.languageService.getLanguage()];
    if (property) {
      langQuery = `&lang=${property}`;
    }
    return this.dynamicScriptLoader.loadScript('ymaps', langQuery);
  }

  // Moscow
  isInDefaultCity(): Observable<boolean> {
    return this.detectGeo().pipe( mergeMap((geo) => {
      if (geo.name === this.defaultCities[this.languageService.getLanguage()]) {
        return of(true);
      } else {
        return of(false);
      }
    }));
  }
}
