import { Component, OnDestroy, AfterViewInit, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { QueryLogService } from '@core/services/common/query-log.service';
import { CityService } from '@core/services/hdbk/city.service';
import { isPlatformBrowser } from '@angular/common';
import { ScrollToTopService } from '@vertera-common/services/scroll-to-top.service';
import { RefService } from '@core/services/user/ref.service';
import { MetaService } from '@ngx-meta/core';
import { META_MESSAGES } from '@core/lib/const/messages';
import { LanguageService } from '@core/services/language.service';
import { NgAnimateScrollService, NotificationService } from '@core/services';
import { AppStorage } from '@core/storage';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
  providers: [ ScrollToTopService ]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  sub$: Subscription = new Subscription();
  title = 'vertera';
  cityChosen: boolean;

  constructor(public angulartics: Angulartics2GoogleGlobalSiteTag,
              public router: Router,
              public route: ActivatedRoute,
              public queryLogService: QueryLogService,
              private cityService: CityService,
              @Inject(PLATFORM_ID) private platformId: any,
              private scrollToTopService: ScrollToTopService,
              private scrollService: NgAnimateScrollService,
              private refService: RefService,
              private readonly meta: MetaService,
              private languageService: LanguageService,
              private notify: NotificationService,
              @Inject(AppStorage) private appStorage: Storage,
            ) {
    angulartics.startTracking();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const sub = this.queryLogService.sendQueryLog(this.route.snapshot.queryParams).subscribe();
      this.sub$.add(sub);
      if (isPlatformBrowser(this.platformId) && !this.cityChosen) {
        this.router.navigate(['geo-lang'], {queryParamsHandling: 'merge'});
      }
      if (this.appStorage.getItem('is_register')) {
        this.scrollService.openOnTop();
        this.notify.openNotification({type: 'top', data: {}});
        this.appStorage.removeItem('is_register');
      }
    }, 1000);
  }

  ngOnInit() {
    this.setMetaTags();
    if (isPlatformBrowser(this.platformId)) {
      this.scrollToTopService.init();
      this.cityChosen = !!this.cityService.getCity();
      // Allow only russia
      if (this.cityService.getCountryId() !== 113) {
        this.cityService.removeCity();
        this.cityChosen = false;
      }
    }
  }

  ngOnDestroy() {
    this.scrollToTopService.destroy();
    this.sub$.unsubscribe();
  }

  setMetaTags() {
    const language = this.languageService.getLanguage();
    this.meta.setTitle(META_MESSAGES.main_page_title[language]);
    this.meta.setTag('description', META_MESSAGES.main_page_description[language]);
  }
}
