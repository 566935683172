import { GqlQuery } from '@core/lib/helpers/graph-ql/graph-ql';

export interface SearchModel {
  id: number;
  image: string;
  title: string;
  slug: string;
  short_description: string;
}

export class SearchQuery {
  query: string | SearchModel;
  city_id: number;
  country_id: number;
  sort: string;
  page: number;
  per__page: number;
  category_slugs: string;
  constructor(public is_hidden = 0) {
  }
}

export const gqlSearchFields = `{
  id
  image
  title
  slug
  short_description
}`;

export class GqlSearchQuery extends GqlQuery {
  fields = gqlSearchFields;
}
