import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { AppStorage } from '@core/storage';

@Injectable({
  providedIn: 'root'
})
export class RefService {
  readonly refParamName = 'bonus';

  constructor(
    private route: ActivatedRoute,
    @Inject(AppStorage) private appStorage: Storage,
  ) {
    this.subscribeToRef();
  }

  setRef(param: string = this.refParamName) {
    const ref = this.route.snapshot.queryParamMap.get(param);
    if (ref) {
      this.appStorage.setItem(`${environment.appName}_ref`, ref);
    }
  }

  getRef() {
    return this.appStorage.getItem( `${environment.appName}_ref`);
  }

  clear() {
    this.appStorage.removeItem( `${environment.appName}_ref`);
  }

  subscribeToRef() {
    this.route.queryParams.subscribe((params) => {
      if (params.ref) {
        this.setRef('ref');
      }
      if (params.bonus) {
        this.setRef();
      }
    });
  }

  getRefUrl(): string {
    const ref = this.getRef();
    if (ref) {
      return `&${this.refParamName}=${ref}`;
    } else {
      return ``;
    }
  }

  getRefUrlToECA(): string {
    const ref = this.getRef();
    if (ref) {
      return `&ref=${ref}`;
    } else {
      return ``;
    }
  }
}
