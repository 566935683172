<ng-container *ngIf="href">
  <a class="no-link" [href]="href" [target]="target" [download]="download">
    <ng-container *ngTemplateOutlet="btnTemplate"></ng-container>
  </a>
</ng-container>
<ng-container *ngIf="!href">
  <ng-container *ngTemplateOutlet="btnTemplate"></ng-container>
</ng-container>
<ng-template #btnTemplate>
  <button #btn
          [type]="role"
          [disabled]="disabled"
          [ngClass]="_class"
          tabindex="-1" [class.round]="round" [class.square]="square" [class.onlyborder]="onlyborder" [class.small]="small">
      <ng-content></ng-content>
  </button>
</ng-template>
