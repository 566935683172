import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Stock, StockSelectQuery } from '@core/models/user/stock.model';
import { Subscription } from 'rxjs';
import { StockService } from '@core/services/user/stock.service';
import { CartViewService } from 'src/app/cart/services/cart-view.service';
import { NotificationService } from '@core/services';

@Component({
  selector: 'app-stock-panel',
  templateUrl: './stock-panel.component.html',
  styleUrls: ['./stock-panel.component.scss']
})
export class StockPanelComponent implements OnInit, OnDestroy {

  sub: Subscription = new Subscription();
  @Input()
  stocks: Stock[];

  @Output() onChanged = new EventEmitter<boolean>();

  constructor(private stockService: StockService, public cartViewService: CartViewService, private notify: NotificationService) { }

  ngOnInit() {
  }

  selectStock(stock: Stock)  {
    if (!stock.is_active) {
      stock.isLoading = true;
      const query = new StockSelectQuery(stock.id);
      const sub = this.stockService.selectStock(query).subscribe(() => {
        this.notify.openNotification({type: 'stock', data: {title: stock.name}});
        stock.isLoading = false;
        const index = this.stocks.findIndex((item) => item.id === stock.id );
        for (const [i, currentStock] of this.stocks.entries()) {
          if (i === index) {
            currentStock.is_active = true;
          } else {
            currentStock.is_active = false;
          }
        }
      }, err => stock.isLoading = false);
      this.sub.add(sub);
      this.onChanged.emit();
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  close() {
    this.onChanged.emit();
  }
}
