import { GqlQuery } from '@core/lib/helpers/graph-ql/graph-ql';

export class VRStorage {
  id: number;
  name: string;
  address: string;
  lat: string;
  lng: string;
  city: {
    id: number;
    name: string;
  };
  country: {
    id: number;
    name: string;
  };
  icon?: string;
}

export class VRStorageQuery {
  country_id: number;
  city_id: number;
  product_ids: string;
  product_slugs: string;
  currency_codes: string;
  is_public: number;
}

export const gqlVRStorageFields = `{
  id
  name
  address
  lat
  lng
  city {
    id
    name
  }
  country {
    id
    name
  }
}`;

export class GqlVRStorageQuery extends GqlQuery {
  fields = gqlVRStorageFields;
}
