import { Injectable, Inject, LOCALE_ID, PLATFORM_ID} from '@angular/core';
import { ILanguage } from '@core/components/languageSwitcherBase';
import { isPlatformBrowser } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class LanguageService  {
  public readonly supportedLanguages = ['ru', 'en', 'es', 'cs', 'bg', 'de'];

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    @Inject(PLATFORM_ID) private platformId: any
    ) { }

  getLanguage(): string {
    return this.locale.substr(0, 2);
  }

  getCurrentLanguage(languages: ILanguage[]): ILanguage {
    const language = this.getLanguage();
    const result = languages.find(lang => lang.code === language);
    return result ? result : languages[0];
  }

  getBrowserLanguage() {
    if (isPlatformBrowser(this.platformId) && navigator.language) {
      const lang = navigator.language.substr(0, 2);
      return this.supportedLanguages.indexOf(lang) > -1 ? lang : this.getLanguage();
    } else {
      return 'en';
    }
  }
}
