import { Injectable, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AppStorage } from '@core/storage';
import { getPublicDomain } from './utils';

@Injectable({
  providedIn: 'root'
})
export class AuthTokenService {
  private host: string = environment.angiolive.url; // TODO: change this host url to proper
  private tokenKey = `${environment.appName}_token`;
  private _tokenSubject: BehaviorSubject<string> = new BehaviorSubject(this.getAuthHeader());

  constructor(
    @Inject(AppStorage) private _appStorage: Storage,
  ) { }

  getAuthorizationHeaderValue(): Observable<string> {
    const token = this.getAuthHeader();
    if (token) {
      return of(`Bearer ${this.getAuthHeader()}`);
    }
    return of(``);
  }

  clearAuthHeaderValue(): void {
    if (document && document.cookie) {
      document.cookie.split(';').forEach((c) => {
        // keep a language and city cookies
        if (!c.includes(`${environment.appName}_lang`) && !c.includes(`${environment.appName}_city`)) {
          document.cookie = c
          .replace(/^ +/, '')
          .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
        }
      });
    }
    /* const domain = */ getPublicDomain(this.host);
    this._appStorage.removeItem(this.tokenKey); // null, domain
  }

  setAuthHeader(token: string): void {
    this._appStorage.removeItem(this.tokenKey);
    /* const domain = */ getPublicDomain(this.host);
    /* if (!domain) {
      this._appStorage.setItem(this.tokenKey, token);
    } else {
      this._appStorage.setItemWithDomain(this.tokenKey, token, domain);
    } */
    this._appStorage.setItem(this.tokenKey, token);
    this._tokenSubject.next(token);
  }


  getAuthHeader(): string {
    let token = this._appStorage.getItem(this.tokenKey);
    token = /^[\w\d-]{30,40}$/.test(token) ? token : '';
    return token;
  }

  getToken(): Observable<string> {
    return this._tokenSubject;
  }
}
