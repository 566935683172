import { Component, OnInit, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppTokenService } from '@core/services/app-token.service';
import { AppStorage } from '@core/storage';
import { LanguageService } from '@core/services/language.service';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss']
})
export class WrapperComponent implements OnInit {

  language: string;

  constructor(
    private appTokenService: AppTokenService,
    @Inject(AppStorage) private appStorage: Storage,
    private languageService: LanguageService,
  ) {
    this.appTokenService.setToken(environment.vertera.token);
    this.appStorage.setItem(`${environment.appName}_app_token`, environment.vertera.token);
  }

  ngOnInit() {
    this.language = this.languageService.getLanguage();
    const body = document.getElementsByTagName('body')[0];
    body.classList.add( this.language );
  }

}
