import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  Inject,
  PLATFORM_ID,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { FavoritesService } from '@core/services';
import {
  FavoritesQuery,
  FavoritesModel,
} from '@core/models/user/favorites.model';
import { PageResult } from '@core/models/page/page.model';
import { CartViewService } from 'src/app/cart/services/cart-view.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '@core/services/auth/auth.service';
import { UserHelperService } from '@core/services/user-helper.service';
import { UserModel } from '@core/models';
import { CategoryComponent } from './components/category/category.component';
import { LanguageService } from '@core/services/language.service';
import { Stock } from '@core/models/user/stock.model';
import { StockService } from '@core/services/user/stock.service';
import { isPlatformBrowser } from '@angular/common';
import { AuthTokenService } from '@core/services/auth/auth-token.service';
import { CityService } from '@core/services/hdbk/city.service';
import { CartService } from '@core/services/cart/cart.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  user: UserModel;
  showAside: boolean;
  favoritesCount: number;
  verteraHost: string;
  isUserLoading: boolean;
  _opened: boolean; // For user menu click
  // For stock panel
  sticky: boolean = false;
  open_panel: boolean;
  stocks: Stock[] = [];
  widthscreen: number;
  language: string;

  newYear = true;

  showSmallGarland: boolean;

  @ViewChild(CategoryComponent, { static: true })
  categoryComponent: CategoryComponent;

  private readonly sub = new Subscription();

  @HostListener('window: resize')
  calcWidth() {
    if (isPlatformBrowser(this.platformId)) {
      this.widthscreen = window.innerWidth;
      this.showSmallGarland = this.widthscreen < 768;
    }
  }

  constructor(
    private favoritesService: FavoritesService,
    public cartViewService: CartViewService,
    private authService: AuthService,
    private userHelperService: UserHelperService,
    private stockService: StockService,
    private languageService: LanguageService,
    private authTokenService: AuthTokenService,
    @Inject(PLATFORM_ID) private platformId: any,
    private cd: ChangeDetectorRef,
    private cityService: CityService,
    private cartService: CartService,
    private router: Router
  ) {}

  ngOnInit() {
    this.subscribeToUser();
    this.subscribeToCart();
    this.getCart();
    this.getStocks();
    this.subscribeToCity();
    // this.subscribeToRouteChange();
    this.language = this.languageService.getLanguage();
    this.verteraHost = environment.vertera.url.replace(
      /\.org/,
      `.org${this.language === 'ru' ? '' : '/' + this.language}`
    );
    this.widthscreen = window.innerWidth;
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
    this.onScroll();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  toggleAside() {
    this.showAside = !this.showAside;
    this._opened = false;
  }

  getFavoritesCount() {
    const sub = this.favoritesService
      .getFavorites(new FavoritesQuery(1, 1))
      .subscribe((data: PageResult<FavoritesModel[]>) => {
        this.favoritesCount = data.pagination.totalCount;
        this.cd.detectChanges();
      });
    this.sub.add(sub);
  }

  subscribeToFavorites() {
    const sub = this.favoritesService.favoritesChange$.subscribe(() => {
      this.getFavoritesCount();
    });
    this.sub.add(sub);
  }

  private getCart() {
    if (!this.router.url.includes('cart')) {
      const sub = this.cartService.getCart().subscribe((data) => {
        this.cartViewService.cartItems$.next(data);
        this.cartViewService.cartItems = data;
        this.cartViewService.calculateCartTotalAmount();
        this.cd.detectChanges();
      });
      this.sub.add(sub);
    }
  }

  private subscribeToCart() {
    const sub = this.cartViewService.cartItems$.subscribe(() =>
      this.cd.detectChanges()
    );
    this.sub.add(sub);
  }

  private subscribeToUser() {
    this.isUserLoading = true;
    this.userHelperService.getUser();
    const sub = this.userHelperService.user$.subscribe((user) => {
      this.isUserLoading = false;
      if (user) {
        this.user = user;
        this.subscribeToFavorites();
      }
    });
    this.sub.add(sub);
  }

  register() {
    this.authService.externalReg();
  }

  login() {
    this.authService.externalLogin();
  }

  logout() {
    this.authService.logOut();
  }

  toggleUserMenu() {
    this._opened = !this._opened;
  }
  toggleStockPanel() {
    this.open_panel = !this.open_panel;
  }

  @HostListener('window: scroll')
  onScroll() {
    this.sticky = window.pageYOffset >= 121;
  }

  onClickedOutsideStock(event) {
    if (!event.target.closest('.stock')) {
      this.open_panel = false;
    }
  }

  getStocks() {
    if (
      isPlatformBrowser(this.platformId) &&
      this.authTokenService.getAuthHeader()
    ) {
      const sub = this.stockService
        .getUserStocks()
        .subscribe((stocks: Stock[]) => {
          this.stocks = stocks;
          this.cd.detectChanges();
        });
      this.sub.add(sub);
    }
  }

  onChanged() {
    this.open_panel = !this.open_panel;
  }
  subscribeToCity() {
    const sub = this.cityService.cityChange$.subscribe(() => this.getStocks());
    this.sub.add(sub);
  }
}
