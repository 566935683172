import { GqlQuery } from '@core/lib/helpers/graph-ql/graph-ql';
import { CountryModel, gqlCountryFields } from '../hdbk/country.model';
import { CityModel } from '../geo/city.model';
import { gqlCityFields } from '../hdbk/city.model';

export class UserBackCallQuery {
  fname: string;
  lname: string;
  phone: string;
  ref_code?: string;
  stat_uuid: string;
  data_json: string;
}

export interface User {
  id: number;
  fname: string;
  mname: string;
  lname: string;
  language: string;
  is_accepted_cookies: boolean;
  active_date_days: number;
}

export interface UserRegModel {
  last_name: string;
  first_name: string;
  ref_code?: string;
  email?: string;
  phone: string;
  is_return_exist_user: boolean;
  language: string;
  country_code: string;
}

export class UserModel implements User {
  id: number;
  language: string;
  fname: string;
  mname: string;
  lname: string;
  is_accepted_cookies: boolean;
  email: string;
  phone: string;
  ref_code: string;
  roles: string[];
  active_date_days: number;
  agr_confirm_date: string;
  editable_fields: any;
  country?: CountryModel;
  city_name?: string;
  country_name?: string;
}

export interface TokenModel {
  token: string;
  expired: string;
}

export type UserConfirmVariantType = 1 | 2; // 1 - phone, 2 - email

export enum UserConfirmVariant {
  phone = 1,
  email
}
export class UserConfirmQuery {
  type_const: UserConfirmVariantType = UserConfirmVariant.phone;
  recipient: string;
}

export class UserValidateQuery extends UserConfirmQuery {
  code: string;
}

export interface UserAddressModel  {
  id?: number;
  country_id: number;
  region_id?: number;
  area_id?: number;
  city_id: number;
  street: string;
  zip_code: string;
  address?: string;

  build_number: string;
  build_block?: string;
  apart_number?: string;

  fname: string;
  lname: string;
  mname: string; // Required ?

  email: string;
  phone: string;
  country: CountryModel;
  city: CityModel;
  parsedAddress?: string;
}

export const gqlUserFields = `{
  id
  fname
  mnamme
  lname
  language
  is_accepted_cookies
  email
  phone
  ref_code
  roles
  active_date_days
  agr_confirm_date
  country ${gqlCountryFields}
  country_name
  city_name
}`;

export const gqlAddressFields = `{
  id
  country_id
  region_id
  area_id
  city_id
  street
  zip_code
  address

  build_number
  build_block
  apart_number

  fname
  lname
  mname

  email
  phone
  country ${gqlCountryFields}
  city ${gqlCityFields}
}`;

export class GqlUserQuery extends GqlQuery {
  fields = gqlUserFields;
}

export class GqlAddressQuery extends GqlQuery {
  fields = gqlAddressFields;
}
